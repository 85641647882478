<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item label="ID" prop="id" v-if="dataForm.id">
        <el-input
          v-model="dataForm.id"
          :disabled="true"
          placeholder="ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="点餐机名称" prop="name">
        <el-input
          v-model="dataForm.name"
          :disabled="disabled"
          placeholder="点餐机名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="机器码" prop="machineCode">
        <el-input
          v-model="dataForm.machineCode"
          :disabled="disabled"
          placeholder="机器码"
        ></el-input>
      </el-form-item>
      <el-form-item label="区域" prop="zoneId">
        <el-select
          v-model="dataForm.zoneId"
          clearable
          filterable
          placeholder="请选择"
          :disabled="disabled"
        >
          <el-option
            v-for="(item, index) in zoneList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="打印机" prop="printerId">
        <el-select
          v-model="dataForm.printerId"
          clearable
          filterable
          placeholder="请选择"
          :disabled="disabled"
        >
          <el-option
            v-for="(item, index) in printerList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { normal } from '@/mixins';
import { getOrgList, getZoneList, getPrinterList } from '@/utils/options.js';
import { keys, pick } from 'lodash';
export default {
  mixins: [normal],
  components: {},
  data() {
    return {
      disabled: false,
      visible: false,
      orgList: [],
      zoneList: [],
      printerList: [],
      dataForm: {
        id: 0,
        name: '',
        machineCode: '',
        orgId: '',
        zoneId: '',
        printerId: '',
      },
      dataRule: {
        name: [
          { required: true, message: '点餐机名字不能为空', trigger: 'blur' },
        ],
        zoneId: [
          { required: true, message: '区域名字不能为空', trigger: 'blur' },
        ],
        printerId: [
          { required: true, message: '打印机不能为空', trigger: 'blur' },
        ],
        machineCode: [
          { required: true, message: '机器码不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  created() {},
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.getOrgList();
      this.getZoneList();
      this.getPrinterList();
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (id) {
          this.$http({
            url: `/os/machine/info/${id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.machine, keys(this.dataForm));
            }
          });
        }
      });
    },
    //供应商列表
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    getPrinterList() {
      getPrinterList().then(({ data }) => {
        if (data && data.code === 0) {
          this.printerList = data.list;
        }
      });
    },
    // 区域列表
    getZoneList() {
      getZoneList().then(({ data }) => {
        if (data && data.code === 0) {
          this.zoneList = data.list;
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/os/machine/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
